import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonRawHtml from 'common/components/entities/RawHtml'
import * as types from 'common/types'
import useManagement, { selectors } from 'publisher/hooks/useManagement'

const HTML_BASE64 =
  'iVBORw0KGgoAAAANSUhEUgAAAPAAAAB4CAMAAAD7aI8VAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAASZQTFRF////1dXVq6urWVlZg4OD/f39dXV1s7Oz5OTkaWlpnJyc19fXjY2NwcHB9vb2eHh4Z2dnr6+v5ubmnp6erKyskJCQZWVlqKiox8fH0dHRhYWFo6OjcnJyiIiIxMTEu7u7+fn5qqqq8PDwe3t7ampq8vLyl5eX4+PjoaGhkpKS6+vrYGBg2dnZjIyMxcXFysrKh4eH3t7ecHBwv7+/goKCqamp6Ojofn5+bm5ufHx8pqampKSkd3d3ycnJi4uLXFxc4eHhw8PDW1tbbGxsvb29b29vf39/wsLCtra2gYGBcXFxYmJisLCwn5+fiYmJ/v7+vLy8Xl5ejo6OeXl5p6en2tradHR08fHx+Pj4Wlpaz8/PYWFhlpaWc3Nzrq6uXV1dpaWltbW1Cp0XrAAAAkpJREFUeJzt2QlTE0EQhuFJJgoKijdGUQEBES+8FeTywvu+FdT//yfs7hzuZicpUgWadL1PFbu9PTtT+ahkIEkIAAAAAAAAAAAAAAAAgCMl06Fd6iRzQ8f1S7nLncixZeUoyoV2qdmuxPZKzVtj3JVef7cNVhqXttqOBNmqbQs8kFx+MLoNvCe5/N4+DJxhjz0jGzgOpZaP/Rc4K/fYc7eK4cTq+zwHjvuLq49I+4DbwAcLqxzS9uEeDHyk0O428FH5OVZYRbPGUZeBj+uhmrr/xEmXgcOYHE61jJzWkTM+n9LjE3qczI+cldbU9IzLwNPn9DiRG7Atazacdxl4LgzL8UJuYFYHLoZLLgMPhst6utJ6t7ys510GvhrCtZZB+w1cD+FGDwauFHUZWP6RvqnnW3/7t+Xyjpzv9mDgNroIXK2/E1xotm3LWpTinsvAS1Isy3ml2V7U9qoUYy4Dr0mx1ijMfblY1uJBDwYuFz+t6jLwQ60eSfG43rUta10rn++Wnmi10HgWi6dSPrPqucvAL7Sa0+qlNW3LGrVyxWXg2q2vtHytlW1Zk8VJzgIvaflGq7dSvEtMchbYduT3ob5lVROTvAX+oPXHWq5PqUneAn/Weqa2ZQ2kJnkLbG+N4hfdsr4mJ7kL/M3+KH+Xw4/kJHeBw5RcbGhnNTnJX+DNWDOSnuQv8Hg98Hp6kr/A9hFWjD/bTEp/F/kvvyLf9sBDFuFXm0kOA9u2lf2E2n3g33I5327S/w8MAAAAAAAAAAAAAAAAAAAA9Lc/MpQtJfYYA64AAAAASUVORK5CYII='

function RawHtml({ entity }) {
  const { options, styles, mobileStyles } = entity
  const isScreenshotMode = useManagement(selectors.isScreenshotMode)

  if (isScreenshotMode) {
    return <img src={`data:image/png;base64,${HTML_BASE64}`} />
  }

  return (
    <StyleWrapper
      styles={styles}
      mobileStyles={mobileStyles}
      desktop={options.appearance.desktop}
      mobile={options.appearance.mobile}
    >
      <CommonRawHtml
        html={options.html}
        htmlAttrId={options.htmlAttrId}
        forceExecute
      />
    </StyleWrapper>
  )
}

RawHtml.propTypes = {
  entity: types.entity.isRequired,
}

export default RawHtml
