import React, { useEffect, useRef } from 'react'
import { AlignType } from '../../../types/styleTypes'
import RawHtmlUi from './ui/RawHtmlUi'

interface CommonRawHtmlInterfaceProps {
  textAlign: AlignType
  mobileTextAlign?: AlignType
  html?: string
  htmlAttrId: string
  forceExecute?: boolean
}

const RawHtml = ({
  html,
  htmlAttrId,
  textAlign,
  mobileTextAlign,
  forceExecute,
}: CommonRawHtmlInterfaceProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!html || !containerRef.current || !isFirstRender.current) {
      return
    }

    isFirstRender.current = false

    const slotHtml = document.createRange().createContextualFragment(html)
    containerRef.current.innerHTML = ''
    containerRef.current.appendChild(slotHtml)
  }, [html, containerRef])

  const commonProps = {
    textAlign: textAlign,
    mobileTextAlign: mobileTextAlign,
    attrId: htmlAttrId,
  }

  return forceExecute ? (
    <RawHtmlUi ref={containerRef} {...commonProps} />
  ) : (
    <RawHtmlUi
      dangerouslySetInnerHTML={{ __html: html ?? '' }}
      {...commonProps}
    />
  )
}

export default RawHtml
