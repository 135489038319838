import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import RawHtmlUi from './ui/RawHtmlUi'

const RawHtml = ({ html, htmlAttrId, styles, forceExecute }) => {
  const containerRef = useRef()
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!html || !containerRef.current || !isFirstRender.current) {
      return
    }

    isFirstRender.current = false

    const slotHtml = document.createRange().createContextualFragment(html)
    containerRef.current.innerHTML = ''
    containerRef.current.appendChild(slotHtml)
  }, [html, containerRef])

  const commonProps = {
    styles: styles,
    attrId: htmlAttrId,
  }

  return forceExecute ? (
    <RawHtmlUi ref={containerRef} {...commonProps} />
  ) : (
    <RawHtmlUi dangerouslySetInnerHTML={{ __html: html }} {...commonProps} />
  )
}

RawHtml.propTypes = {
  htmlAttrId: PropTypes.string,
  html: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.string),
}

RawHtml.defaultProps = {
  styles: {},
}

export default RawHtml
